<template>
  <div class="flex flex-col flex-grow border-r border-gray-200 bg-white h-full overflow-y-auto" id="side-navigation-menu">
    <div class="flex items-center flex-shrink-0 px-4 pt-4 pb-2">
      <div class="text-gray-800 text-2xl">Trade In</div>
    </div>
    <div class="mx-4 relative rounded shadow-sm">
      <input type="text" placeholder="Search" class="text-gray-600 focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-200 rounded">
      <div class="absolute inset-y-2 right-2 flex items-center pointer-events-none h-5 w-5 text-gray-400">
        <i class="far fa-search"></i>
      </div>
    </div>
    <div class="mt-4 flex flex-row justify-between px-4">
      <button type="button" @click="newTradeIn" class="w-1/2 mr-1 inline-flex items-center px-3 py-2 shadow-sm font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-700 sm:text-sm">
        <i class="far fa-plus-circle"></i> <span class="ml-2">Trade In</span>
      </button>
      <button type="button" @click="openInstructions" class="w-1/2 ml-1 inline-flex items-center px-3 py-2 shadow-sm font-medium rounded text-orange-500 bg-orange-100 hover:bg-orange-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-orange-300 sm:text-sm">
        <i class="far fa-film"></i><span class="ml-2">Instructions</span>
      </button>
    </div>
    <div class="mt-4 flex-grow flex flex-col">
      <nav class="flex-1 px-2 bg-white">
        <a v-for="state in states" :key="state.state" href="#"
           :class="['mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded focus:outline-none', state.state == currentState ? 'text-gray-900 bg-gray-100 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-200 transition ease-in-out duration-150' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150']">
          <span :class="['text-xl mr-4', state.icon]" style="min-width:25px;"></span>
          {{ state.name }}
          <span class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 font-medium rounded-full bg-gray-100 transition ease-in-out duration-150 group-hover:bg-gray-200 group-focus:bg-gray-200">
            {{ state.value }}
          </span>
        </a>
      </nav>
    </div>

  </div>
</template>

<script>

export default {
  name: "Panel",
  data: function() {
    return {
      currentState: "new_entries",
      states: [
        {state: 'new_entries', name: "New entries", icon: "fal fa-play", value: 0, valueError: 0},
        {state: 'pending', name: "Pending", icon: "fal fa-hourglass-start", value: 0, valueError: 0},
        {state: 'verify', name: "Verify", icon: "fal fa-user-check", value: 0, valueError: 0},
        {state: 'offers', name: "Offers", icon: "fal fa-poll-people", value: 0, valueError: 0},
        {state: 'proposed', name: "Proposed", icon: "fal fa-satellite-dish", value: 0, valueError: 0},
        {state: 'refused', name: "Refused", icon: "fal fa-stop-circle", value: 0, valueError: 0},
        {state: 'accepted', name: "Accepted", icon: "fal fa-thumbs-up", value: 0, valueError: 0},
        {state: 'archive', name: "Archive", icon: "fal fa-archive", value: 0, valueError: 0},
      ],
    }
  },
  methods: {
    openInstructions() {

    },
    newTradeIn() {
      this.$store.commit('seller/openItem', 0);
    },
  }
}
</script>

<style scoped>

</style>